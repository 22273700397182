<template>
  <div class="location">
    <!--
    <h1>{{ msg }}</h1>
    -->
    <div class="address-wrapper">
      <div class="buttons single">
        <button
          class="button button--outline address__search"
          @click="searchAddress"
        >
          <i class="fas fa-map-marker-alt"></i> Find min adresse
        </button>
      </div>
      <div class="address__helper-text">
        Angiv afhentningsadresse, eventuelt ud fra din nuværende lokation
      </div>
      <div class="address">
        <div class="address__line">
          <input
            class="input address__line__road"
            type="text"
            placeholder="Firmanavn *"
            v-model="companyName"
          />
          <span class="address__delete" @click="clearCompanyName"
            ><i class="fas fa-times"></i
          ></span>
        </div>
        <div class="address__line">
          <input
            class="input address__line__road"
            type="text"
            placeholder="Vej"
            v-model="address"
          />
          <span class="address__delete" @click="clearAddress"
            ><i class="fas fa-times"></i
          ></span>
        </div>
        <div class="address__line">
          <div class="address__line__zip">
            <input
              class="input "
              type="number"
              placeholder="Postnr *"
              v-model="zip"
              @keyup="searchZip"
            />
            <span class="address__delete" @click="clearZip"
              ><i class="fas fa-times"></i
            ></span>
          </div>
          <input
            class="input address__line__city"
            type="text"
            placeholder="By"
            v-model="city"
          />
        </div>
      </div>
    </div>

    <div class="comment-wrapper">
      <textarea
        class="textarea"
        placeholder="Kommentar til adresse"
        v-model="comment"
      ></textarea>
    </div>

    <div class="buttons single js-next">
      <button
        class="button address__button"
        @click="openPopup"
        :disabled="!validAddress"
      >
        <i class="far fa-arrow-alt-circle-right"></i>Videre
      </button>
    </div>

    <div class="popup" v-show="popup" ref="popup">
      Er adressen korrekt?
      <div>
        <span v-if="companyName"> {{ companyName }}<br /></span>
        <span v-if="address">{{ address }}<br /></span>
        {{ zip }} {{ city }}
      </div>
      <div class="buttons double">
        <button
          class="button address__button"
          @click="saveAddress"
          :disabled="!validAddress"
        >
          Ja
        </button>
        <button
          class="button address__button"
          @click="closePopup"
          :disabled="!validAddress"
        >
          Nej
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FindLocation",
  props: {
    msg: String,
  },
  data: function() {
    return {
      companyName: null,
      address: "",
      roadName: null,
      number: null,
      zip: null,
      city: null,
      comment: null,
      //validAddress: false,
      popup: false,
    };
  },
  computed: {
    validAddress() {
      if (
        this.zip != null &&
        this.companyName != null &&
        this.companyName.length > 2
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    openPopup() {
      if (this.$refs.popup) {
        document.body.classList.add("no-scroll");

        this.popup = true;
      }
    },
    closePopup() {
      this.popup = false;
      document.body.classList.remove("no-scroll");
    },

    searchAddress() {
      const vm = this;
      navigator.geolocation.getCurrentPosition(function(pos) {
        vm.getLocation(pos.coords);
      });
    },
    clearAddress() {
      this.address = null;
      (this.roadName = null), (this.number = null);
    },
    clearCompanyName() {
      this.companyName = null;
    },
    clearZip() {
      this.zip = null;
      this.city = null;
    },
    searchZip() {
      if (this.zip.length === 4) {
        this.getCity();
      }
    },
    saveAddress() {
      this.closePopup();
      this.$store.state.location = {
        companyName: this.companyName,
        address: this.address,
        zip: this.zip,
        city: this.city,
        comment: this.comment,
      };

      this.$router.push("/order");
    },
    setAddress(roadName, number, zip, city, comment) {
      this.roadName = roadName ? roadName : "";
      this.number = number ? number : "";
      this.address = this.roadName + " " + this.number;
      this.zip = zip ? zip : "";
      this.city = city ? city : "";
      this.comment = comment ? comment : "";
    },
    async getLocation(coords) {
      const res = await fetch(
        "https://dawa.aws.dk/adgangsadresser/reverse?x=" +
          coords.longitude +
          "&y=" +
          coords.latitude +
          "&struktur=mini"
      );
      const data = await res.json();
      this.setAddress(
        data.vejnavn,
        data.husnr,
        data.postnr,
        data.postnrnavn,
        null
      );

      const next = document.querySelector(".js-next");
      if (next) {
        next.scrollIntoView();
      }
    },
    async getCity() {
      const res = await fetch("https://dawa.aws.dk/postnumre?nr=" + this.zip);
      const data = await res.json();

      if (data[0]) {
        this.city = data[0].navn;
      }
    },
    enterDirections(e) {
      this.showAddress = !e.target.checked;
    },
    getLocationFromCookie() {
      const userInfo = this.$cookie.getCookie(this.$store.state.cookieName);
      if (userInfo) {
        this.presetAddress(userInfo.location);
      }
    },
    presetAddress(location) {
      if (location) {
        this.address = location.address;
        this.zip = location.zip;
        this.city = location.city;
        this.comment = location.comment;
      }
    },
  },
  mounted() {
    if (this.$store.state.codes === undefined) {
      this.$router.push("/");
    } else {
      this.getLocationFromCookie();
    }
    if (this.$refs.popup) {
      document.body.appendChild(this.$refs.popup);
    }
  },
};
</script>
<style scoped lang="scss">
.location {
  padding-bottom: 100px;
}
.address {
  margin: 0;
  &__helper-text {
    margin: 10px 0;
  }

  &-wrapper {
    .buttons {
      margin: 0;
    }
  }

  &__line {
    display: flex;
    position: relative;

    &__road {
    }

    &__zip {
      flex: 1;
      position: relative;
    }
    &__city {
      margin-left: 1rem;
      flex: 2;
    }
  }
  &__search {
    position: relative;
    flex-direction: column;

    .fa-map-marker-alt {
      font-size: 2rem;
    }
  }
  &__delete {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0.5rem 0;
    padding: 1rem;
    line-height: 1.5;
    cursor: pointer;
  }
}
.checkbox {
  display: block;
  margin: 0.5rem 0 2rem 0;
}
.button--outline {
  color: #2C4390;
  border-color: #2C4390;
}
</style>
