<template>
  <div class="container location">
    <FindLocation msg="Hvor?" />
  </div>
</template>

<script>
import FindLocation from "@/components/FindLocation.vue";
//import Back from "@/components/Back.vue";

export default {
  name: "Location",
  components: {
    FindLocation,
    //Back,
  },
};
</script>
